<template>
  <div>
    <CEditor
      ref="geditor"
      :value="general"
      :placeholder="`${$t('update')}...`"
      class="mb-4"
    >
      <template v-slot:header>
        <CTypo tstyle="subheadline">
          {{ $t('placementNote.general') }}
        </CTypo>
      </template>
      <CButton
        :loading="submitLoading"
        variant="primary"
        size="small"
        @click="save"
      >
        {{ $t('save') }}
      </CButton>
    </CEditor>
    <CEditor ref="peditor" :value="publicp" :placeholder="`${$t('update')}...`">
      <template v-slot:header>
        <CTypo tstyle="subheadline">
          {{ $t('placementNote.public') }}
        </CTypo>
      </template>
      <CButton
        :loading="submitLoading"
        variant="primary"
        size="small"
        @click="save"
      >
        {{ $t('save') }}
      </CButton>
    </CEditor>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { THIS_SHOP } from '@/store/gettersTypes';
import { GET_SHOP, UPDATE_SHOP } from '@/store/actionTypes';
import { SET_SUCCESS_NOTIFICATION } from '@/store/mutationsTypes';

export default {
  computed: {
    ...mapGetters([THIS_SHOP]),
    publicp() {
      return this.thisShop.publicPlacementNotes;
    },
    general() {
      return this.thisShop.generalPlacementNotes;
    },
    submitLoading() {
      return this.thisShop.submitLoading;
    },
  },

  created() {
    this.getShop();
  },

  methods: {
    ...mapActions([GET_SHOP, UPDATE_SHOP]),
    ...mapMutations([SET_SUCCESS_NOTIFICATION]),
    async save() {
      let newPublic = this.$refs.peditor.getJSON();
      let newGeneral = this.$refs.geditor.getJSON();
      if (newPublic.content.length === 1 && !newPublic.content[0].content) {
        newPublic = { type: 'doc', content: [] };
      }
      if (newGeneral.content.length === 1 && !newGeneral.content[0].content) {
        newGeneral = { type: 'doc', content: [] };
      }
      this.updateShop({
        publicPlacementNotes: newPublic,
        generalPlacementNotes: newGeneral,
      });
      this.setSuccessNotification({ content: null, show: true });
    },
  },
};
</script>
